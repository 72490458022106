<template>
  <v-combobox
    v-if="mode == 'tags'"
    v-model="control.model[control.name]"
    v-bind="tagControl"
    multiple
    light
    small-chips
    deletable-chips
    class="jexy-tags"
    append-outer-icon="fa-tags"
    @click:append-outer="textMode"
  />
  <v-textarea
    v-else
    v-model="textTags"
    v-bind="tagControl"
    rows="3"
    class="jexy-string"
    append-outer-icon="fa-i-cursor"
    @input="convertTags"
    @click:append-outer="tagMode"
  />

</template>

<script>

// https://codepen.io/virtualadrian/pen/pOeVPX?editors=1111

// Mixin
import Control from './Mixin'

export default {
  name: 'Tags',
  mixins: [ Control ],
  data() {
    return {
      search: '', //sync search
      mode: 'tags',
      textTags: ''
    }
  },
  computed: {
    tagControl () {
      return {
        ...this.control,
        label: this.mode === 'tags' ? `${this.control.label} (separate with tab or enter)` : `${this.control.label} (separate with commas)`
      }
    }
  },
  mounted () {
    const value = this.control.get()
    this.textTags = value ? value.join(', ') : ''
  },
  methods: {
    updateTags() {
      this.$nextTick(() => {
        const tags = this.control.get()
        tags.push(...this.search.split(","))
        this.control.set(tags)
        this.$nextTick(() => {
          this.search = ""
        })
      })
    },
    convertTags() {
      const tagArray = this.textTags ? this.textTags.split(',').map((tag) => tag.trim()) : []
      this.control.set(tagArray)
    },
    textMode() {
      const modelTags = this.control.get()
      const textTags = modelTags ? modelTags.join(', ') : ''
      this.textTags = textTags
      this.mode = 'text'
    },
    tagMode() {
      this.convertTags()
      this.mode = 'tags'
    }
  }
}

</script>
